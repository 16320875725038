import React from 'react';

const SplitSection = ({
  id,
  primarySlot,
  secondarySlot,
  reverseOrder,
  smallPic,
}) => (
  <section id={id} className="pt-10">
    <div className="container mx-auto px-16 items-center flex flex-col lg:flex-row">
      <div className={`lg:w-${smallPic ? "2/3" : "1/2"}`}>{primarySlot}</div>
      <div
        className={`flex justify-center mt-10 lg:mt-0 w-full lg:w-${
          smallPic ? "1/3" : "1/2"
        } ${reverseOrder && `order-last lg:order-first`}`}
      >
        {secondarySlot}
      </div>
    </div>
  </section>
);

export default SplitSection;
