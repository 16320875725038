import React from "react";
import Button from "../components/Button";
import Card from "../components/Card";
import CustomerCard from "../components/CustomerCard";
import Layout from "../components/layout/Layout";
import SplitSection from "../components/SplitSection";
import AnchorLink from "react-anchor-link-smooth-scroll";
import HeroImage from "../static/hero-image.jpg";
import Beauty from "../static/beauty.jpg";
import revenue from "../static/revenue.png";
import coffee from "../static/coffee.png";
import nature from "../static/nature.png";
import kuba from "../static/kuba.jpg";
import igor from "../static/igor.jpeg";

const Index = () => (
  <Layout>
    <section
      className="pt-20 md:pt-40 pb-20 md:pb-40 bg-cover bg-bottom tracking-wider"
      style={{
        backgroundColor: "rgba(0, 0, 0, .50)",
        backgroundImage: `url(${HeroImage})`,
        backgroundBlendMode: "darken",
      }}
    >
      <div className="container mx-auto px-8 max-w-3xl text-white">
        <div className="text-center">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Rozwiń swój biznes dzięki subskrypcjom
          </h1>
          <p className="text-xl lg:text-2xl mt-8 font-light">
            Nasze rozwiązanie umożliwia łatwe wdrożenie sprzedaży
            subskrypcyjnej, zwiększenie lojalności klientów i wzrost przychodów.
          </p>
          <p className="mt-8 md:mt-12	">
            <Button
              size="lg"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("anchor-clients").click();
              }}
            >
              Dowiedz się więcej
            </Button>
            <AnchorLink
              className="sr-only"
              href="#clients"
              offset={50}
              id="anchor-clients"
            >
              Dla kogo?
            </AnchorLink>
          </p>
        </div>
      </div>
    </section>

    <section id="clients">
      <div className="container mx-auto text-center pt-20 px-10">
        <h2 className="text-4xl lg:text-4xl font-semibold">Dla kogo?</h2>
      </div>

      <SplitSection
        smallPic={true}
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">
              Gastronomia, Kawiarnie, Salony Piękności...
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              To tylko niektóre z branż mogące skorzystać z modelu
              subskrypcyjnego. Wszędzie tam gdzie istnieje możliwość zbudowania
              długotrwałej relacji z klientem, nasze narzędzie może Ci pomóc.
              Nie jesteś przekonany czy to odpowiednie rozwiązanie dla Ciebie?{" "}
              <AnchorLink href="#contact" offset={100}>
                Skontaktuj się z nami
              </AnchorLink>
            </p>
          </div>
        }
        secondarySlot={
          <img
            src={Beauty}
            alt="Salon Piękności"
            className="rounded-full max-h-60 lg:max-h-full"
          />
        }
      />
    </section>

    <section id="features" className="py-20 lg:pt-20">
      <div className="container mx-auto text-center px-10">
        <h2 className="text-4xl lg:text-4xl font-semibold pb-10">
          Najważniejsze możliwości
        </h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">
                Tworzenie pakietów produktów
              </p>
              <p className="mt-4">
                Stwórz plany subskrypcji i opublikuj je w naszej aplikacji.
                Udostępnij ofertę klientom, aby mogli wybrać odpowiedni dla
                siebie pakiet.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Zarządzanie subskrypcjami</p>
              <p className="mt-4">
                Twoi klienci samodzielnie mogą rozpocząć i zakończyć
                subskrypcję. Ty kontrolujesz zużycie produktów i usług w ramach
                pakietu.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card>
              <p className="font-semibold text-xl">Obsługa płatności</p>
              <p className="mt-4">
                Nasza platforma płatności automatycznie odnowi subskrypcję w
                kolejnym cyklu rozliczeniowym. Środki trafiają bezpośrednio na
                Twoje konto.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>

    <section id="benefits">
      <div className="container mx-auto text-center px-10">
        <h2 className="text-4xl lg:text-4xl font-semibold">
          Korzyści z wdrożenia sprzedaży subskrypcyjnej
        </h2>
      </div>
      <SplitSection
        id="services"
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">
              Stały przychód i przewidywalność wyników finansowych
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Sprzedaż subskrypcyjna pozwala na dokładniejsze planowanie i
              zarządzanie finansami firmy zapewniając większą stabilność i
              bezpieczeństwo finansowe przedsiębiorstwa.
            </p>
          </div>
        }
        secondarySlot={
          <img
            src={revenue}
            alt="Stały przychód"
            className="max-h-60 lg:max-h-full"
          />
        }
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">
              Zwiększenie lojalności klientów
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Subskrypcja skłania klientów do regularnych zakupów i zwiększa
              zaangażowanie w markę lub produkt. Klienci otrzymują stały dostęp
              do usług i produktów, co pozwala na utrzymywanie długoterminowych
              relacji.
            </p>
          </div>
        }
        secondarySlot={
          <img
            src={coffee}
            alt="Lojalność klientów"
            className="max-h-60 lg:max-h-full"
          />
        }
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">
              Ułatwienie planowania dostaw
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Przewidywalny poziom sprzedaży umożliwia lepsze zarządzanie
              zasobami i minimalizowanie odpadów. Ułatwia to firmom planowanie
              zaopatrzenia, minimalizuje koszty działalności, a także pozwala
              być bardziej ekologicznym.
            </p>
          </div>
        }
        secondarySlot={
          <img src={nature} alt="Ekologia" className="max-h-60 lg:max-h-full" />
        }
      />
    </section>

    <section id="use-cases" className="pt-20">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl lg:text-4xl font-semibold pb-10 px-10">
          Twórcy
        </h2>
        <div className="flex items-center justify-center flex-col sm:flex-row md:-mx-3">
          <div className="px-3">
            <CustomerCard
              name="Igor Rodzik"
              url="https://www.linkedin.com/in/igor-rodzik-6443a095/"
              image={igor}
            />
          </div>
          <div className="px-3">
            <CustomerCard
              name="Jakub Żurawski"
              url="https://www.linkedin.com/in/jakubzurawski/"
              image={kuba}
            />
          </div>
        </div>
      </div>
    </section>

    <section
      className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center"
      id="contact"
    >
      <h3 className="text-5xl font-semibold">Skontaktuj się z nami</h3>
      <p className="mt-8 text-xl font-light px-10 md:px20">
        Napisz do nas aby dowiedzieć się więcej. Z przyjemnością zorganizujemy
        spotkanie na którym przedstawimy Ci nasz system i zalety sprzedaży
        subskrypcyjnej.
      </p>
      <p className="mt-8">
        <Button
          size="xl"
          onClick={(e) => {
            window.location.href = "mailto:hello@recurrite.com";
            e.preventDefault();
          }}
        >
          Wyślij wiadomość
        </Button>
      </p>
    </section>
  </Layout>
);

export default Index;
